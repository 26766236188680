import i18n from 'i18next';
import { Container, Graphics, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { setBottomContainerTotalWin, setCurrentBonus } from '../../../gql/cache';
import { Logic } from '../../../logic';
import type Animation from '../..//animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';
import { LAYOUT_OPTIONS, buttonTextStyle, freeRoundsTextStyles } from '../textStyles';

export class FreeRoundsPopup extends Popup {
  protected popup: Container;

  protected background: Sprite;

  protected freeRounds: TextField;

  protected freeRoundsLabel: TextField;

  protected freeRoundsAmount: TextField;

  protected backgroundFadeInAnimation: Animation;

  protected backgroundFadeOutAnimation: Animation;

  private bindedCallback = () => {
    this.backgroundFadeOutAnimation.start();
    PopupController.the.closeCurrentPopup();
    this.visible = false;
    eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    if (!setBottomContainerTotalWin() && Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS) {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }
  };

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.freeRounds = this.initFreeRoundsText();
    this.freeRoundsAmount = this.initFreeRoundsAmount();
    this.freeRoundsLabel = this.initFreeRoundsLabel();
    this.background = this.initBackground();
    this.popup = this.initPopup();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.init();
  }

  protected initPopup(): Container {
    const container = new Container();
    const innerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.bgColor)
      .drawRoundedRect(0, 0, LAYOUT_OPTIONS.width, LAYOUT_OPTIONS.height, LAYOUT_OPTIONS.borderRadius)
      .endFill();
    innerBg.x = 2;
    innerBg.y = 2;
    const outerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.borderColor)
      .drawRoundedRect(
        0,
        0,
        LAYOUT_OPTIONS.width + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.height + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.borderRadius,
      )
      .endFill();
    const closeBtn = this.createButton();

    container.addChild(
      outerBg,
      innerBg,
      closeBtn,
      this.freeRounds.text,
      this.freeRoundsAmount.text,
      this.freeRoundsLabel.text,
    );
    closeBtn.x = LAYOUT_OPTIONS.width / 2 - closeBtn.width / 2;
    closeBtn.y = LAYOUT_OPTIONS.height - (closeBtn.height * 3) / 2;

    return container;
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popup);
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.5;
    return sprite;
  }

  private initFreeRoundsLabel(): TextField {
    const text = new TextField(i18n.t('freeRoundsLabel'), 100, 100, freeRoundsTextStyles);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 57);
    return text;
  }

  private initFreeRoundsAmount(): TextField {
    const text = new TextField('', 100, 100, { ...freeRoundsTextStyles, fontSize: 50 });
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 115);
    return text;
  }

  private initFreeRoundsText(): TextField {
    const text = new TextField(i18n.t('freeRounds'), 100, 100, freeRoundsTextStyles);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 175);
    return text;
  }

  override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    this.background.position.set(width / 2, height / 2);

    if (width - 50 < LAYOUT_OPTIONS.width) {
      this.popup.scale.set(width / (LAYOUT_OPTIONS.width + 50));
    } else if (height - 100 < LAYOUT_OPTIONS.height) {
      this.popup.scale.set(height / (LAYOUT_OPTIONS.height + 100));
    } else {
      this.popup.scale.set(1);
    }
    this.popup.y = height / 2 - this.popup.height / 2;
    this.popup.x = width / 2 - this.popup.width / 2;
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public override show(_props?: PopupProps): void {
    this.freeRoundsAmount.setText(`${setCurrentBonus().rounds}`);
    this.visible = true;
    this.backgroundFadeInAnimation.start();
  }

  private createButton(): Container {
    const width = LAYOUT_OPTIONS.width / 3;
    const height = LAYOUT_OPTIONS.width / 8;
    const borderWidth = 3; // Define the border width
    const { borderColor } = LAYOUT_OPTIONS; // Define the border color
    const radius = LAYOUT_OPTIONS.borderRadius; // Border radius

    const button = new Graphics();

    // Draw the specific borders (only top-right and bottom)
    button
      .lineStyle(borderWidth, borderColor)
      .moveTo(width - radius, 0) // Start drawing at the top-right corner
      .arcTo(width, 0, width, radius, radius) // Top-right corner arc
      .lineTo(width, height - radius) // Right border downwards
      .arcTo(width, height, width - radius, height, radius) // Bottom-right corner
      .lineTo(radius, height) // Draw the bottom border
      .arcTo(0, height, 0, height - radius, radius); // Bottom-left corner arc

    // Draw the filled button
    button.beginFill(LAYOUT_OPTIONS.buttonColor).drawRoundedRect(0, 0, width, height, radius).endFill();

    const text = new TextField(i18n.t('close'), 100, 100, buttonTextStyle);
    text.text.anchor.set(0.5);
    text.text.position.set(width / 2, height / 2);

    button.interactive = true;
    button.cursor = 'pointer';
    button.on('pointertap', () => this.bindedCallback());
    button.addChild(text.text);

    return button;
  }
}
