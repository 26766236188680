import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';
import type * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import type { Play } from '@phoenix7dev/audio-api/dist/d';

import { ISongs } from '../config';
import { EventTypes, FeatureState, GameMode, ISettledBet, buyFeatureBonusesId } from '../global.d';
import {
  setBetAmount,
  setBlocksLoading,
  setBrokenGame,
  setIsBuyFeaturePurchased,
  setIsDuringBigWinLoop,
  setIsSpinInProgress,
  setIsSuspended,
  setSlotConfig,
} from '../gql/cache';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
  eventManager,
} from '../slotMachine/config';

import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

//
export const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState]!;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME || mode === GameMode.FREE_ROUND_BONUS;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.LIGHTNING_BONUS;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  if (multiplier >= GREAT_WIN_AMOUNT_LIMIT && multiplier < 5000) return WinStages.EpicWin;
  return WinStages.MaxWin;
};

export const getBonusMode = (bonusId: string) => {
  return bonusId === buyFeatureBonusesId[FeatureState.LIGHTNING_BONUS]
    ? GameMode.LIGHTNING_BONUS
    : GameMode.SPECIAL_SHOT_BONUS;
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lines.length)) / 100;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
  isBlocksLoading,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isBlocksLoading: boolean | null;
}): boolean => {
  if (isBlocksLoading) {
    return false;
  }

  if ((isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>): PIXI.Filter => {
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Base_Loop;
    case GameMode.LIGHTNING_BONUS:
      return ISongs.BGM_LIGHT_Loop;
    case GameMode.SPECIAL_SHOT_BONUS:
      return ISongs.BGM_SHOT_Loop;
    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  if (setBrokenGame()) {
    setIsSuspended(false);
    AudioApi.unSuspend();
    AudioApi.changeRestriction(false, []);
    eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    setIsSuspended(false);
    AudioApi.unSuspend();
    const listToPlay: Play[] = [];
    if (setIsDuringBigWinLoop()) {
      listToPlay.push({ type: ISongs.BigWin_Loop });
    }
    switch (mode) {
      case GameMode.BASE_GAME:
        listToPlay.push({ type: ISongs.BGM_BG_Base_Loop }, { type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
        break;
      case GameMode.LIGHTNING_BONUS:
        listToPlay.push({ type: ISongs.BGM_LIGHT_Loop });
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        listToPlay.push({ type: ISongs.BGM_SHOT_Loop });
        break;
      default:
        listToPlay.push({ type: ISongs.BGM_BG_Base_Loop }, { type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
        break;
    }
    AudioApi.changeRestriction(false, listToPlay);
  }
};

export const cascadeEase = (x: number): number => {
  const c1 = 1.70158;
  const c2 = c1 * 1;

  return x < 0.5 ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : Math.pow(x, 2);
};

export const fallBackReelPosition = () => {
  if (!setIsBuyFeaturePurchased()) {
    setBlocksLoading(false);
    setIsSpinInProgress(false);
    eventManager.emit(EventTypes.ROLLBACK_REELS);
  } else {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUYFEATURE);
  }
};
