import { Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import { setCurrentBonus, setIsAutoSpins, setIsLeftHandMode, setIsModalOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  PopupTypes,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyFeatureBtn extends ViewContainer {
  override name = 'BuyFeatureBtn';

  private btn: Sprite;

  private text: TextField;

  private isDisabled: boolean;

  private isLeftHandMode: boolean;

  private isPortraitMode: boolean;

  private isLandscapeMode: boolean;

  private isDesktop!: boolean;

  public gameContainerBottomPosition: number;

  public underGameContainerSpaceHeight: number;

  public applicationSize: { width: number; height: number };

  public gameContainerSize: {
    width: number;
    height: number;
    x: number;
    y: number;
  };

  constructor() {
    super();
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.applicationSize = { width: 0, height: 0 };
    this.gameContainerSize = { width: 0, height: 0, x: 0, y: 0 };
    this.gameContainerBottomPosition = 0;
    this.underGameContainerSpaceHeight = 0;
    this.zIndex = 2;
    this.isLeftHandMode = setIsLeftHandMode() && isMobile.any;
    this.isPortraitMode = false;
    this.isLandscapeMode = false;
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, this.handleLeftHandMode.bind(this));

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.LIGHTNING_BONUS:
      case GameMode.SPECIAL_SHOT_BONUS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyFeatureBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyFeatureBtnDefault));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnDefault);
        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnDefault);
        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 150, 300, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress || this.isFreeRoundBonus) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnDisabled);

      this.text.text.style = buyFeatureDisableTextStyle;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnDefault);

      this.text.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private get isFreeRoundBonus(): boolean {
    return this.isDisabled && setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
  }

  private handleLeftHandMode = (isLhm: boolean): void => {
    this.isLeftHandMode = isLhm && isMobile.any;
    this.handlePosition();
  };

  private handlePosition(): void {
    this.y = this.applicationSize.height / 2;

    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 95.5)
      : calcPercentage(this.applicationSize.width, 4.5);

    let y = isMobile.any
      ? calcPercentage(this.applicationSize.height, 94)
      : calcPercentage(this.applicationSize.height, 87);

    if (this.isPortraitMode) {
      this.scale.set(this.underGameContainerSpaceHeight / 850);
      x = this.isLeftHandMode
        ? this.applicationSize.width - calcPercentage(this.gameContainerSize.width, 12.5)
        : calcPercentage(this.applicationSize.width, 12.5);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 25);
    }

    if (this.isLandscapeMode) {
      this.scale.set(Math.min(0.71, this.applicationSize.height / 900));

      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 93) - (this.width / 2 - 30)
        : this.width / 2 + 30;
      y = this.applicationSize.height / 2;
    }
    if (this.isDesktop) {
      this.scale.set(Math.min(0.71, this.applicationSize.height / 1200));

      x = this.isLeftHandMode ? calcPercentage(this.applicationSize.width, 93) : this.width / 2 + 30;
      y = this.applicationSize.height / 2;
    }

    this.x = x;
    this.y = y;
  }

  private gameContainerResize = (gameContainerSizes: {
    appWidth: number;
    appHeight: number;
    gameContainerWidth: number;
    gameContainerHeight: number;
    underGameContainerSpaceHeight: number;
  }): void => {
    this.isLandscapeMode = gameContainerSizes.appWidth >= gameContainerSizes.appHeight;
    this.isPortraitMode = gameContainerSizes.appWidth < gameContainerSizes.appHeight;
    this.isDesktop = !isMobile.any;
    this.applicationSize.width = gameContainerSizes.appWidth;
    this.applicationSize.height = gameContainerSizes.appHeight;
    this.gameContainerSize.width = gameContainerSizes.gameContainerWidth;
    this.gameContainerSize.height = gameContainerSizes.gameContainerHeight;
    this.gameContainerBottomPosition = gameContainerSizes.gameContainerHeight;
    this.underGameContainerSpaceHeight = gameContainerSizes.underGameContainerSpaceHeight;

    this.handlePosition();
  };
}

export default BuyFeatureBtn;
