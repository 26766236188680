import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsFirstLoad,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setLightningBonusTotalWin,
  setProgress,
  setSkipIntroScreen,
  setSlotConfig,
  setSpecialShotBonusTotalWin,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import type { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  getBonuses,
  getSlotGql,
  getSlotLoadProgressInfoGql,
  getUserBonuses,
  getUserGql,
  slotConfigGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import type { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  isBuyFeatureEnabled,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  wait,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const getCoinValue = (slotConfig: ISlotData) => {
  if (setBrokenGame()) {
    return setCurrentBonus().coinValue;
  }
  return slotConfig.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
  let coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slotConfig.clientSettings.coinAmounts.default);

  if (setBrokenGame()) {
    coinAmount = setCurrentBonus().coinAmount;
  }
  return coinAmount;
};

const currencyLoaded = () => {
  if (setCurrency() === 'FUN') {
    setIsLeftHandMode(false);
    setIsTurboSpin(false);
    setIsSoundOn(true);
    setIsMiniPayTable(true);
    setIsEnabledSpaceSpin(true);
    setSkipIntroScreen(false);
    setIsFirstLoad(true);
  }
};

const getUserBalanceFn = async () => {
  const userBalance = await client.query<{ user: IUserBalance }>({
    query: getUserGql,
    fetchPolicy: 'network-only',
  });
  setUserBalance(userBalance.data.user);
  setCurrency(userBalance.data.user.balance.currency);
  currencyLoaded();
};

const getLastBetFn = async () => {
  const betsData = await client.query<{ bets: ISlotHistoryData }>({
    query: slotHistoryGql,
    variables: {
      input: { last: 1, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });
  const lastBet = betsData.data.bets.edges[0];

  if (lastBet) {
    setUserLastBetResult(lastBet.node);
  }
};

const getPurchasableBonusesFn = async () => {
  const bonusData = await client.query<{ bonuses: IBonus[] }>({
    query: getBonuses,
    variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  setBonuses(bonusData.data.bonuses);
};

const getSlotDataFn = async () => {
  const slotData = await client.query<{ slot: ISlotData }>({
    query: getSlotGql,
    variables: { input: { id: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  const { slot } = slotData.data;
  const slotConfig = {
    clientSettings: slot.clientSettings,
    settings: slot.settings,
    previewImage: slot.previewImage,
    icons: slot.icons,
    reels: slot.reels,
    lineSets: slot.lineSets,
    isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
  };
  const coinValue = getCoinValue(slot);
  const coinAmount = getCoinAmount(slot);

  setSlotConfig({
    ...setSlotConfig(),
    ...slotConfig,
  });
  setGameMode(GameMode.BASE_GAME);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setWinAmount(setUserLastBetResult().result.winCoinAmount);
  setBetAmount(setCoinAmount() * slot.lineSets[0]!.coinAmountMultiplier);
};

const checkBrokenGameFn = async () => {
  const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
  // its locally for testing.
  // const userBonusData: ApolloQueryResult<{
  //   userBonuses: UserBonus[];
  // }> = JSON.parse(JSON.stringify(userBonusData1));

  // userBonusData.data.userBonuses.push({
  //   ...(setCurrentBonus() as UserBonus),
  //   isActive: true,
  //   gameMode: GameMode.FREE_ROUND_BONUS,
  //   currentRound: 0,
  //   rounds: 1,
  //   totalWinAmount: 0,
  //   coinAmount: 1,
  //   coinValue: 1000,
  //   betId: '123',
  //   id: '1e716f58-d5de-45a7-bb73-a72e0f827654',
  //   bonusId: '1e716f58-d5de-45a7-bb73-a72e0f827654',
  // });
  setLightningBonusTotalWin(0);
  setSpecialShotBonusTotalWin(0);

  const userBonusesList = userBonusData.data.userBonuses;
  const isActiveUserBonus = Boolean(userBonusesList.length);

  if (isActiveUserBonus) {
    setBrokenGame(true);
    const specialShotBonus = userBonusesList.find((e) => e.data.specialShotValues?.length);
    const lightningShotBonus = userBonusesList.find((e) => e.data.firstLotteryValues?.length);
    const frbBonus = userBonusData.data.userBonuses.find(
      (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
    ) as UserBonus;

    const frbTotalAmount = frbBonus?.totalWinAmount ? frbBonus?.totalWinAmount / frbBonus.coinValue : 0;
    const lightningTotalAmount = lightningShotBonus?.totalWinAmount
      ? lightningShotBonus?.totalWinAmount / lightningShotBonus.coinValue
      : 0;
    const specialTotalAmount = specialShotBonus?.totalWinAmount
      ? specialShotBonus?.totalWinAmount / specialShotBonus.coinValue
      : 0;
    if (userBonusesList.length === 1 && frbBonus) {
      setCurrentBonus({
        ...frbBonus,
        isActive: true,
        gameMode: GameMode.FREE_ROUND_BONUS,
        currentRound: 0,
        rounds: frbBonus.rounds,
        totalWinAmount: 0,
      });
      if (frbTotalAmount) {
        setBottomContainerTotalWin(frbTotalAmount);
        setFreeRoundsTotalWin(frbTotalAmount);
      }
    } else {
      if (specialShotBonus) {
        setSpecialShotBonusTotalWin(specialTotalAmount);
        setBottomContainerTotalWin(
          frbBonus && specialShotBonus.data.frbReferenceId ? frbTotalAmount : specialTotalAmount,
        );
        setCurrentBonus({
          ...specialShotBonus,
          isActive: true,
          gameMode: GameMode.SPECIAL_SHOT_BONUS,
          currentRound: specialShotBonus.roundsPlayed,
          rounds: specialShotBonus.rounds + specialShotBonus.roundsPlayed,
          totalWinAmount: 0,
        });
      }
      if (lightningShotBonus) {
        setLightningBonusTotalWin(lightningTotalAmount);
        setBottomContainerTotalWin(
          frbBonus && lightningShotBonus.data.frbReferenceId ? frbTotalAmount : lightningTotalAmount,
        );
        setCurrentBonus({
          ...lightningShotBonus,
          isActive: true,
          gameMode: GameMode.LIGHTNING_BONUS,
          currentRound: lightningShotBonus.roundsPlayed,
          rounds: lightningShotBonus.rounds + lightningShotBonus.roundsPlayed,
          totalWinAmount: 0,
        });
      }
      if (frbBonus && (lightningShotBonus?.data.frbReferenceId || specialShotBonus?.data.frbReferenceId)) {
        setFreeRoundsBonus({
          ...frbBonus,
          isActive: true,
          gameMode: GameMode.FREE_ROUND_BONUS,
          currentRound: 0,
          rounds: frbBonus.rounds,
          totalWinAmount: frbTotalAmount,
          coinAmount: frbBonus.coinAmount,
          coinValue: frbBonus.coinValue,
          id: frbBonus.id,
        });
        setFreeRoundsTotalWin(frbTotalAmount - (lightningTotalAmount || specialTotalAmount));
      }
    }
  }
};

const LoadScreen: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { data: slotLoadProgressInfo } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { isSoundOn } = slotConfig!;
  const { progress } = slotLoadProgressInfo!;
  const [isShowContent, setShowContent] = useState(false);

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      setSlotConfig({
        ...setSlotConfig(),
        sessionId,
      });
      setIsAuthorized(!!slotLoadProgressInfo);
    },
  });

  useEffect(() => {
    const getUserBalance = getUserBalanceFn;
    const getPurchasableBonuses = getPurchasableBonusesFn;
    const getLastBet = getLastBetFn;
    const checkBrokenGame = checkBrokenGameFn;
    const getSlotData = getSlotDataFn;

    setShowContent(true);

    new Loader({ asynchronous: false })
      .stage(10, ELoaderStages.AUTH, async (stage, _resources) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
          isFirstLoad: setIsFirstLoad,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BROKEN_GAME, async (stage, _resources) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage, _resources) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(60, ELoaderStages.CUSTOM, async (stage, _resources) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(80, ELoaderStages.AUDIO, async (_stage, _resources) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          isSoundEnabled: isSoundOn,
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
          onSuspended: setIsSuspended,
          restricted: setSkipIntroScreen(),
        }).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioApi.isRestricted && !(!AudioApi.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async (_resources) => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.GAME_READY, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
  }, []);

  if (!isShowContent) return null;

  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
      </div>
      <ProgressBar
        className={styles['progressBar'] as string}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
