import { utils } from 'pixi.js';

export * from './textStyles';
class CustomEmitter extends utils.EventEmitter {
  override on(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.on(event, fn, context);
  }

  override once(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.once(event, fn, context);
  }

  override addListener(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.addListener(event, fn, context);
  }

  override emit(event: string | symbol, ...args: unknown[]): boolean {
    return super.emit(event, ...args);
  }
}
export const eventManager = new CustomEmitter();

(window as unknown as { eventManager: CustomEmitter }).eventManager = eventManager;

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  LIGHTNING_BONUS,
  LIGHTNING_BONUS_END,
  SPECIAL_SHOT_BONUS,
  SPECIAL_SHOT_BONUS_END,
  FREE_ROUNDS,
  FREE_ROUNDS_END,
}

export enum SlotType {
  BASE,
  CASCADE,
}

export const SLOT_TYPE = SlotType.BASE;

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = true;
export const APPLICATION_FPS = 60;

// RESET ANIMATION
export const RESET_ANIMATION_BASE_DURATION = 500;
export const RESET_ANIMATION_TURBO_DURATION = 200;

// SLOT SETTINGS
export const SLOT_SCALE = 1.1;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.6;

// BIG WIN SETTINGS
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_AMOUNT_LIMIT = 40;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_AMOUNT_LIMIT = 60;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_AMOUNT_LIMIT = 4999;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// EPIC WIN SETTINGS
export const MAX_WIN_COUNT_UP_MULTIPLIER = 0.9;

// GENERAL SLOT MACHINE SETTINGS

// This is maximum win (X)
export const MAX_WIN = 5000;
export const REELS_AMOUNT = 1;
export const SLOTS_PER_REEL_AMOUNT = 4;
export const REEL_WIDTH = 382;
export const SLOT_WIDTH = 382;
export const SLOT_HEIGHT = 187;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 36;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 36;

// REELS SETTINGS
export const BASE_DISAPPEARING_DURATION = 300;
export const TURBO_DISAPPEARING_DURATION = 150;
export const BASE_APPEARING_DURATION = 300;
export const TURBO_APPEARING_DURATION = 150;
export enum ReelState {
  IDLE,
  DISAPPEARING,
  WAITING,
  APPEARING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// SPIN ANIMATION SETTINGS
export const DELAY_BETWEEN_REELS = 120;
export const FORCE_STOP_CASCADE_ANIMATION_DURATION = 200;
export const FORCE_STOP_CASCADE_PER_EACH_DURATION = 0;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH + SLOT_WIDTH / 2 - 10;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.65;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 2000;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
  MaxWin,
}

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_X = -190;
export const FEATURE_BTN_WIDTH = 200;
export const FEATURE_BTN_HEIGHT = 200;

// BUY FEATURE POPUP
export const FEATURE_POPUP_WIDTH = 1030;
export const MOBILE_FEATURE_POPUP_WIDTH = 1300;
export const FEATURE_POPUP_HEIGHT = 1415;
export const MOBILE_FEATURE_POPUP_HEIGHT = 1100;
export const FEATURE_POPUP_POSITION_X = 0;
export const MOBILE_FEATURE_POPUP_POSITION_X = 0;
export const FEATURE_POPUP_POSITION_Y = 0;
export const MOBILE_FEATURE_POPUP_TITLE_POSITION_X = 650;

// BUY FEATURE CANCEL
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 100;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = 100;
