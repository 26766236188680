import { Group, Layer } from '@pixi/layers';
import { Graphics, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../../config';
import { EventTypes, ISettledBet } from '../../../global.d';
import {
  baseGameCountUp,
  setBetAmount,
  setBetResult,
  setCurrentIsTurboSpin,
  setIsAutoSpins,
  setIsGameOver,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setProgressBarItems,
  setSlotConfig,
  setThorRequestedBlockCount,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import { States } from '../../../logic/config';
import { fallBackReelPosition, getBetResult, getCascadeSlots, isRegularMode } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import { CallbackPriority, TweenProperties } from '../../animations/d';
import { createBlockLandingAnimation } from '../../animations/helper';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import {
  BASE_APPEARING_DURATION,
  DELAY_BETWEEN_REELS,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../../config';
import type { Block, Icon, ThorRewards } from '../../d';
import { GameProgress } from '../../gameProgress/gameProgress';
import CascadeReel from '../reel/cascadeReel';
import type { Reel } from '../reel/reel';
import type Slot from '../slot';

import type { ReelsContainer } from './reelsContainer';

class CascadeReelsContainer extends ViewContainer implements ReelsContainer {
  public reel!: Reel & ViewContainer;

  private landingContainer: ViewContainer = new ViewContainer();

  public isSoundPlayed = false;

  public isForceStopped = false;

  public timeScaleAmount = 1;

  public layer: Layer;

  public slotGroup: Group;

  public maskArea: Graphics;

  public rollback = false;

  constructor(slotIds: Block[]) {
    super();
    this.slotGroup = new Group(1);
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT - 10)
      .endFill();
    this.landingContainer.sortableChildren = true;
    this.addChild(this.maskArea);
    this.mask = this.maskArea;
    this.layer = new Layer(this.slotGroup);
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.initReel(slotIds, this.slotGroup);
    this.addChild(this.layer);
    this.addChild(this.landingContainer);
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupReelsTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.RESET_REELS_ANIMATION_START, this.createResetReelsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.isForceStopped = false;
      this.setTurboSpinTimescale();
      this.startSpinAnimation();
    });

    eventManager.addListener(EventTypes.TOGGLE_TURBO_SPIN, (isTurboSpin: boolean) => {
      if (this.isForceStopped) return;
      this.timeScaleAmount = isTurboSpin ? 1.5 : 1;
    });
    this.sortableChildren = true;
    this.setTurboSpinTimescale();
  }

  private setTurboSpinTimescale(): void {
    const isTurboSpin = setIsTurboSpin();
    this.timeScaleAmount = isTurboSpin ? 1.5 : 1;
  }

  private throwTimeoutError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsTimeoutErrorMessage(true);
      fallBackReelPosition();
      eventManager.emit(EventTypes.THROW_ERROR);
    }
  }

  public getCurrentSlots(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      spinResult.push(
        setSlotConfig().icons.find(
          (icon) => icon.id === this.reel.slots.find((slot) => slot.id === SLOTS_PER_REEL_AMOUNT - j - 1)!.slotId,
        )!,
      );
    }
    return spinResult;
  }

  public startSpinAnimation(): void {
    setIsGameOver(false);
    setThorRequestedBlockCount(setThorRequestedBlockCount() + 1);
    if (setBetResult() && !Logic.the.isReadyForStop) {
      this.reel.createSpinAnimation();
      Logic.the.isReadyForStop = true;
      const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
      const slotIds = generatedReelSet.map((e) => ({
        slotId: e.iconId as SlotId,
        rewardName: e.rewardName as ThorRewards,
      }));
      const slotIcons = getCascadeSlots({
        slotIds,
      });
      eventManager.emit(EventTypes.SETUP_REEL_POSITIONS, slotIcons, Logic.the.isStoppedBeforeResult);
      setThorRequestedBlockCount(0);
    } else if (setThorRequestedBlockCount() >= 6) {
      setThorRequestedBlockCount(0);
      this.throwTimeoutError();
    } else {
      if (this.rollback) {
        this.rollback = false;
        setThorRequestedBlockCount(0);
        return;
      }
      this.reel.changeState(ReelState.WAITING);
      eventManager.emit(EventTypes.THOR_REQUEST_BLOCKS);
    }
    this.reel.isPlaySoundOnStop = true;
  }

  public createResetReelsAnimation(): void {
    const betResult = getBetResult(setBetResult());
    const newBetResult = JSON.parse(JSON.stringify(betResult)) as ISettledBet;
    const { generatedReelSet } = newBetResult.bet.data.features;
    generatedReelSet.splice(generatedReelSet.length - 1, 1);
    setBetResult(newBetResult);
    const { slots } = this.reel;
    const removedSlot = slots.splice(slots.length - 1, 1);
    if ((removedSlot[0] as Slot).rewardName && !(removedSlot[0] as Slot).rewardName.includes('payout')) {
      const progressBarUpdate = (removedSlot[0] as Slot).rewardName;
      let y = isMobile.any ? -120 : -80;
      let x = 200;
      let progressItems = setProgressBarItems();
      if ((removedSlot[0] as Slot).rewardName === 'invincible_icon') {
        progressItems = progressItems.filter((e) => e !== 'invincible');
      }
      if ((removedSlot[0] as Slot).rewardName === 'w_power') {
        progressItems = progressItems.filter((e) => e !== 'wPower');
      }
      y += isMobile.any ? 100 * progressItems.length : 160 * progressItems.length;
      x += isMobile.any ? 25 : 50;

      if ((removedSlot[0] as Slot).rewardName === 'mp_icon') {
        y = -220;
        x = 170;
      }

      eventManager.emit(EventTypes.START_TRACK_ANIMATION, { x: -100, y: 100 }, { x, y }, 500, () => {
        const soundToPlay = (removedSlot[0] as Slot).rewardValue >= 10 ? ISongs.EFFECT_MOVE2 : ISongs.EFFECT_MOVE1;
        AudioApi.play({
          type: soundToPlay,
          stopPrev: true,
        });
        eventManager.emit(
          EventTypes.UPDATE_GAME_PROGRESS_ICON,
          progressBarUpdate,
          true,
          (removedSlot[0] as Slot).rewardValue,
        );
      });
    }
    slots.forEach((slot, index) => {
      slot.id = slots.length - index - 1;
    });
    const slotPayout = (removedSlot[0] as Slot).payoutAmount;
    if (slotPayout) {
      let isFirstPayout = false;
      const currentWin = setBetAmount() * slotPayout;
      if (baseGameCountUp() === 0) {
        isFirstPayout = true;
        baseGameCountUp(1);
      }
      const basegameCountUpVal = baseGameCountUp();
      const wonAmount = isFirstPayout ? currentWin : currentWin + basegameCountUpVal;
      eventManager.emit(
        EventTypes.START_TRACK_ANIMATION,
        { x: -100, y: 100 },
        {
          x: 0,
          y: 300,
        },
        500,
        () => {
          AudioApi.play({
            type: slotPayout > 10 ? ISongs.EFFECT_MOVE2 : ISongs.EFFECT_MOVE1,
            stopPrev: true,
          });
          eventManager.emit(EventTypes.START_COUNT_UP, isFirstPayout ? 0 : basegameCountUpVal, wonAmount, 0);
        },
      );
      // });
      baseGameCountUp(wonAmount);
    }
    const animation = new AnimationGroup();
    const chain = new AnimationChain();
    chain.appendAnimation(Tween.createDelayAnimation(0));
    const group = new AnimationGroup();
    this.reel.slots.forEach((slot) => {
      const propertyBeginValue = slot.y;
      const target = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
      let delay = 0;
      if (slot.id < SLOTS_PER_REEL_AMOUNT) {
        delay = slot.id * 100 + BASE_APPEARING_DURATION;
      }
      delay /= this.timeScaleAmount;
      group.addAnimation(
        new Tween({
          object: slot,
          property: TweenProperties.Y,
          propertyBeginValue,
          target,
          duration: delay,
        }),
      );
    });
    chain.appendAnimation(group);
    animation.addAnimation(chain);
    animation.addOnComplete(() => {
      if (GameProgress.progressCounter !== 50) {
        AudioApi.play({ type: ISongs.BLOCK_LAND, stopPrev: true });
      }
      eventManager.emit(EventTypes.RESET_REELS_ANIMATION_END, removedSlot[0]);
    });
    animation.start();
  }

  public rollbackReels(): void {
    this.rollback = true;
    if (setIsAutoSpins()) setIsAutoSpins(false);
    if (Logic.the.state.name !== States.IDLE) {
      Logic.the.changeState(States.IDLE);
    }
  }

  public initReel(slotIds: Block[], slotGroup: Group): void {
    const cascades = getCascadeSlots({
      slotIds,
    });
    this.reel = new CascadeReel(slotIds, cascades, slotGroup);
    this.addChild(this.reel);
  }

  public forceStopReels(): void {
    this.isForceStopped = true;
    this.timeScaleAmount = 2;
  }

  public setupReelsTarget(icons: Icon[], isStopped: boolean): void {
    const isTurboSpin = setCurrentIsTurboSpin() && isRegularMode(Logic.the.controller.gameMode);
    this.isSoundPlayed = false;
    const reel = this.reel as CascadeReel;
    const appearingChain = new AnimationChain();
    if (!isTurboSpin && !isStopped) {
      appearingChain.appendAnimation(Tween.createDelayAnimation(0 * DELAY_BETWEEN_REELS));
    }
    const appearingAnimation = new AnimationGroup();
    appearingChain.appendAnimation(appearingAnimation);
    reel.animation?.appendAnimation(appearingChain);
    reel.createSlots(
      icons.map((icon: Icon) => icon.id),
      this.slotGroup,
    );
    for (let i = 0; i < reel.slots.length; i++) {
      const slot = reel.slots[reel.slots.length - i - 1] as Slot;
      const target = (SLOTS_PER_REEL_AMOUNT - i - 0.5) * SLOT_HEIGHT;
      const propertyBeginValue = (SLOTS_PER_REEL_AMOUNT - i - 5) * SLOT_HEIGHT;
      slot.y = propertyBeginValue;
      let delay = 0;
      if (slot.id < SLOTS_PER_REEL_AMOUNT) {
        delay = slot.id * 100 + BASE_APPEARING_DURATION;
      }

      delay /= this.timeScaleAmount;

      const appearing = createBlockLandingAnimation(slot, target, propertyBeginValue, delay, false);

      appearing.addOnComplete(() => {
        if (slot.id < SLOTS_PER_REEL_AMOUNT) {
          slot.onSlotStopped();
          if (slot.id === SLOTS_PER_REEL_AMOUNT - 1) {
            eventManager.emit(EventTypes.REELS_STOPPED);
            Logic.the.currentSpinResult = this.getCurrentSlots();
          }
          slot.visible = true;

          if (slot.id + 1 == SLOTS_PER_REEL_AMOUNT) {
            AudioApi.play({ type: ISongs.BLOCK_LAND, stopPrev: true });
          }
        }
      }, CallbackPriority.HIGH);
      appearingAnimation.addAnimation(appearing);
    }
    appearingAnimation.addOnStart(() => {
      reel.changeState(ReelState.APPEARING);
    });
    appearingAnimation.addOnComplete(() => {
      reel.changeState(ReelState.IDLE);
    });
    reel.animation?.start();
  }

  public setSlotsVisibility(): void {
    this.reel.removeChildAt(this.reel.slots.length - 1);
  }
}

export default CascadeReelsContainer;
