import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { EventTypes, LightningEntryAnimation, SpecialShotBonusColors } from '../../global.d';
import { eventManager } from '../config';
import { SpecialThorMovements } from '../d';

class SpecialThor {
  protected specialThor: Spine;

  public screenHeight = 0;

  public screenWidth = 0;

  public currentColor = SpecialShotBonusColors.DEFAULT;

  public multiplier = 1;

  public set setMultiplier(newMultiplier: number) {
    this.multiplier = newMultiplier;
    if (this.multiplier === 10) {
      this.currentColor = SpecialShotBonusColors.DEFAULT;
    } else if (this.multiplier > 10 && this.multiplier <= 50) {
      this.currentColor = SpecialShotBonusColors.YELLOW;
    } else if (this.multiplier > 50 && this.multiplier < 1000) {
      this.currentColor = SpecialShotBonusColors.GREEN;
    } else if (this.multiplier >= 1000) {
      this.currentColor = SpecialShotBonusColors.RED;
    }
  }

  constructor() {
    this.specialThor = this.initSpecialThor();
    this.initSpecialThorListeners();
  }

  get getSpecialThor(): Spine {
    return this.specialThor;
  }

  private initSpecialThor(): Spine {
    const specialThor = new Spine(Loader.shared.resources['specialThor']!.spineData!);
    specialThor.name = 'SpecialThor';
    specialThor.zIndex = 10;
    return specialThor;
  }

  private onResize(width: number, height: number): void {
    this.screenHeight = height;
    this.screenWidth = width;
  }

  private initSpecialThorListeners(): void {
    this.specialThor.state.addListener({
      complete: (entry: LightningEntryAnimation) => {
        switch (entry.animation.name) {
          case SpecialThorMovements.defaultThreshold:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoop, true);
            break;
          case SpecialThorMovements.yellowThreshold:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopYellow, true);
            break;
          case SpecialThorMovements.greenThreshold:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopGreen, true);
            break;
          case SpecialThorMovements.redThreshold:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopRed, true);
            break;
          case SpecialThorMovements.collectEnergyIn:
            this.setSpecialThorAnimations(SpecialThorMovements.collectEnergyInLoop, true);
            break;
          case SpecialThorMovements.getReadyInGreen:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopGreen, true);
            break;
          case SpecialThorMovements.getReadyInYellow:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopYellow, true);
            break;
          case SpecialThorMovements.getReadyInRed:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoopRed, true);
            break;
          case SpecialThorMovements.getReadyIn:
            this.setSpecialThorAnimations(SpecialThorMovements.getReadyLoop, true);
            break;
          case SpecialThorMovements.shotIn:
          case SpecialThorMovements.shotInGreen:
          case SpecialThorMovements.shotInYellow:
          case SpecialThorMovements.shotInRed:
            this.setSpecialThorAnimations(SpecialThorMovements.shotInLoop, true);
            break;
          default:
            break;
        }
      },
    });

    eventManager.addListener(EventTypes.SPECIAL_THOR_IN, () => {
      this.setSpecialThorAnimations(SpecialThorMovements.collectEnergyIn, false);
    });

    eventManager.addListener(EventTypes.EXECUTE_SPECIAL_SHOT, () => {
      switch (this.currentColor) {
        case SpecialShotBonusColors.YELLOW:
          this.setSpecialThorAnimations(SpecialThorMovements.shotInYellow, false);
          break;
        case SpecialShotBonusColors.GREEN:
          this.setSpecialThorAnimations(SpecialThorMovements.shotInGreen, false);
          break;
        case SpecialShotBonusColors.RED:
          this.setSpecialThorAnimations(SpecialThorMovements.shotInRed, false);
          break;
        case SpecialShotBonusColors.DEFAULT:
          this.setSpecialThorAnimations(SpecialThorMovements.shotIn, false);
          break;
        default:
          break;
      }
    });
    eventManager.addListener(EventTypes.RESIZE, (width, height) => this.onResize(width, height));
  }

  public startSpecialThorAnimation(): void {
    this.setSpecialThorAnimations(SpecialThorMovements.getReadyIn, false);
  }

  public setSpecialThorAnimations(animation: SpecialThorMovements, loop: boolean): void {
    this.specialThor.state.setAnimation(0, animation, loop);
  }
}

export default SpecialThor;
