import i18n from 'i18next';
import { Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrency, setIsContinueAutoSpinsAfterFeature, setLightningBonusTotalWin } from '../../../gql/cache';
import { normalizeCoins, showCurrency } from '../../../utils';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { popupTextStyle } from '../textStyles';

import LightningBonusEndPopupBackgroundStatic from './lightningBonusEndPopupBackgroundStatic';

export class LightningBonusEndPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: LightningBonusEndPopupBackgroundStatic | LightningBonusEndPopupBackgroundStatic;

  protected wonAmount!: TextField;

  protected youWonText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation: Animation;

  public fadeInAnimation: Animation;

  public backgroundFadeInAnimation: Animation;

  public backgroundFadeOutAnimation: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.zIndex = 3;
    this.initBackground();
    this.initTextContainer();
    this.initWonAmount();
    this.initYouWonText();
    this.fadeInAnimation = this.initFadeInAnimation();
    this.fadeOutAnimation = this.initFadeOutAnimation();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();

    this.init();
  }

  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.popupBackground = new LightningBonusEndPopupBackgroundStatic(this);
  }

  private initTextContainer(): void {
    this.textContainer = new ViewContainer();
  }

  private initYouWonText(): void {
    this.youWonText = new TextField(i18n.t('bonusGameYouWon'), 350, 200, popupTextStyle);
    this.youWonText.text.anchor.set(0.5, 0.5);
    this.youWonText.text.position.set(0, -50);
  }

  private initWonAmount(): void {
    this.wonAmount = new TextField('', 350, 200, popupTextStyle);
    this.wonAmount.text.anchor.set(0.5, 0.5);
    this.wonAmount.text.position.set(0, 50);
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    this.textContainer.addChild(this.youWonText.getText(), this.wonAmount.getText());
    this.popupBackground.addChild(this.textContainer);
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 200,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 200,
    });
  }

  public override show(_props?: PopupProps): void {
    AudioApi.stop({ type: ISongs.BGM_LIGHT_Loop });
    AudioApi.play({ type: ISongs.LIGHT_ExitPopup });
    const totalWin = setLightningBonusTotalWin();
    this.wonAmount.setText(
      `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(totalWin),
        showCurrency: showCurrency(setCurrency()),
      })}`,
    );
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    clearTimeout(this.autoSkipDelay);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    this.position.set(width / 2, height / 2);
  }
}
