import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EnterProps, EventTypes, GameMode, GeneratedReelSet, ISettledBet, UserBonus } from '../../global.d';
import {
  baseGameCountUp,
  setBetAmount,
  setBetResult,
  setBonusGameDataRequested,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setGuaranteeAmount,
  setIsBuyFeaturePurchased,
  setIsFreeSpinsWin,
  setIsGameOver,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsSuperPowerEnabled,
  setLastRegularWinAmount,
  setLightningBonusTotalWin,
  setSlotConfig,
  setSpecialShotBonusTotalWin,
  setStressful,
  setUserBalance,
  setWPower,
  setWinAmount,
  setWinCoinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import i18n from '../../i18next';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import { Icon, ThorMovements, ThorPowers, ThorRewards } from '../../slotMachine/d';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBetResult, getCascadeSlots, getWinStage, updateCoinValueAfterBonuses } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class FreeRoundsController extends Controller {
  public gameMode: GameMode = GameMode.FREE_ROUND_BONUS;

  public static the = new FreeRoundsController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  private skipIdle = false;

  protected constructor() {
    super();
    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    });
  }

  private nextCascade(): void {
    eventManager.once(EventTypes.RESET_REELS_ANIMATION_END, () => {
      if (setIsSuperPowerEnabled()) {
        eventManager.once(EventTypes.THOR_AFTER_SUPER_POWER_IN, () => {
          setIsSuperPowerEnabled(false);
          this.startNextCascade();
        });
      } else {
        this.startNextCascade();
      }
    });

    eventManager.emit(EventTypes.RESET_REELS_ANIMATION_START, 0);
  }

  private startNextCascade(): void {
    const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
    const slotIds = generatedReelSet.map((e) => ({
      slotId: e.iconId as SlotId,
      rewardName: e.rewardName as ThorRewards,
    }));

    const cascadeIcons = getCascadeSlots({
      slotIds,
    });
    if (!cascadeIcons.length) {
      setIsGameOver(true);
      eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
      setTimeout(() => {
        AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
        this.thorGameOver();
      }, 500);
    } else {
      const currentGuaranteeAmount = setGuaranteeAmount();
      const wPower = setWPower();
      if (wPower) {
        this.thorWPowerHit(generatedReelSet, cascadeIcons);
      } else if (currentGuaranteeAmount) {
        this.thorGuaranteeHit(generatedReelSet, cascadeIcons);
      } else {
        this.thorHit(generatedReelSet, cascadeIcons);
      }
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }

    // workaround when we buy feature durring offline mode
    if (
      prevState === States.INIT ||
      prevState === States.INTRO ||
      prevState === States.BROKEN_GAME ||
      setIsBuyFeaturePurchased()
    ) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    if (this.skipIdle) {
      this.skipIdle = false;
      return;
    }
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
      setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    // this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    Logic.the.changeState(States.CASCADE_PRESENTATION);
  }

  public override enterCascadeState(_prevState: States): void {
    const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
    const slotIds = generatedReelSet.map((e) => ({
      slotId: e.iconId as SlotId,
      rewardName: e.rewardName as ThorRewards,
    }));
    eventManager.on(EventTypes.NEXT_CASCADE, this.nextCascade.bind(this));
    const cascadeIcons = getCascadeSlots({
      slotIds,
    });
    this.thorHit(generatedReelSet, cascadeIcons);
  }

  public override exitCascadeState(_nextState: States): void {
    eventManager.removeAllListeners(EventTypes.NEXT_CASCADE);
    eventManager.removeAllListeners(EventTypes.RESET_REELS_ANIMATION_END);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setLastRegularWinAmount(winCoinAmount);
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      setLastRegularWinAmount(winCoinAmount);
      setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
    }
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    const bonuses = result.bet.data.bonuses;
    const specialShotBonus = bonuses.find((e) => e.data.specialShotValues?.length);
    const lightningShotBonus = bonuses.find((e) => e.data.firstLotteryValues?.length);

    if (specialShotBonus) {
      setFreeRoundsBonus({ ...setCurrentBonus() });
      setSpecialShotBonusTotalWin(result.bet.result.winCoinAmount);
      setIsFreeSpinsWin(true);
      setCurrentBonus({
        ...specialShotBonus!,
        isActive: true,
        isFinished: false,
        currentRound: 0,
      });
      if (setBottomContainerTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }
      AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
      setTimeout(() => {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.SPECIAL_SHOT_BONUS, {
          bonus: specialShotBonus as UserBonus,
        });
      }, 1000);
      this.resetBetResult();
      return;
    } else if (lightningShotBonus) {
      setFreeRoundsBonus({ ...setCurrentBonus() });
      setLightningBonusTotalWin(result.bet.result.winCoinAmount);
      setIsFreeSpinsWin(true);
      setCurrentBonus({
        ...lightningShotBonus!,
        isActive: true,
        isFinished: false,
        currentRound: 0,
      });
      AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
      setTimeout(() => {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.LIGHTNING_BONUS, {
          bonus: lightningShotBonus as UserBonus,
        });
      }, 1000);
      this.resetBetResult();
      return;
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  private resetBetResult(): void {
    const betResult = getBetResult(setBetResult());
    const newBetResult = JSON.parse(JSON.stringify(betResult)) as ISettledBet;
    newBetResult.bet.data.features.generatedReelSet = [];
    setBetResult(newBetResult);
  }

  public override enterController(prevGameMode: GameMode, props?: EnterProps): void {
    setBonusGameDataRequested(false);
    updateCoinValueAfterBonuses();
    AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    eventManager.emit(EventTypes.SHOW_WIN_LABEL);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (setFreeRoundsBonus().isActive) {
      if (!props?.endBonus) {
        setCurrentBonus({ ...setFreeRoundsBonus() });
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      } else {
        setCurrentBonus({
          ...setCurrentBonus(),
          rounds: setFreeRoundsBonus().rounds,
          currentRound: setFreeRoundsBonus().rounds,
        });
      }
    }
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);

    if (prevGameMode === GameMode.LIGHTNING_BONUS) {
      if (setLightningBonusTotalWin() > 0) {
        const totalWin = setBrokenGame() ? setLightningBonusTotalWin() : setWinAmount() + setLightningBonusTotalWin();
        setWinAmount(totalWin);
        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, totalWin);
      }
    }
    if (prevGameMode === GameMode.SPECIAL_SHOT_BONUS) {
      if (setSpecialShotBonusTotalWin() > 0) {
        const totalWin = setBrokenGame()
          ? setSpecialShotBonusTotalWin()
          : setWinAmount() + setSpecialShotBonusTotalWin();
        setWinAmount(totalWin);
        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, totalWin);
      }
    }
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    if (props?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound,
      );
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
        callback: () => {
          setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          Logic.the.changeState(States.IDLE);
          eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        },
      });
    } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      Logic.the.changeState(States.IDLE);
      eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    result.bet.data.features.generatedReelSet.reverse();
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    const freeBet = result.bet.data?.bonuses?.find((e) => e.isFreeBet) as UserBonus;
    if (freeBet) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + result.bet.result.winCoinAmount);
    }
    setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);

    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setWinCoinAmount(result.bet.result.winCoinAmount);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    setBetResult(result);
  }

  private thorHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    eventManager.emit(EventTypes.THOR_ATTACK, ThorMovements.attack, ThorMovements.idle);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards(currentBlockToBreak.rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private thorGameOver(): void {
    setGuaranteeAmount(0);
    setIsSuperPowerEnabled(false);
    setWPower(false);
    const winCoinAmount = setWinCoinAmount();
    this.resetBetResult();
    if (winCoinAmount) {
      if (winCoinAmount > baseGameCountUp()) {
        eventManager.emit(
          EventTypes.START_TRACK_ANIMATION,
          { x: 180, y: -200 },
          {
            x: 0,
            y: 300,
          },
          500,
          () => {
            AudioApi.play({
              type: ISongs.EFFECT_MOVE1,
              stopPrev: true,
            });

            eventManager.emit(EventTypes.START_COUNT_UP, baseGameCountUp(), winCoinAmount, 0);
            eventManager.emit(EventTypes.THOR_IDLE);
            setTimeout(() => {
              Logic.the.changeState(States.AFTER_WIN);
              baseGameCountUp(0);
            }, 2000);
          },
        );
      } else {
        eventManager.emit(EventTypes.THOR_IDLE);
        setTimeout(() => {
          Logic.the.changeState(States.AFTER_WIN);
          baseGameCountUp(0);
        }, 1000);
      }
    } else {
      eventManager.emit(EventTypes.THOR_IDLE);
      setTimeout(() => {
        Logic.the.changeState(States.AFTER_WIN);
        baseGameCountUp(0);
      }, 0);
    }
  }

  private thorWPowerHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    const currentGuaranteeAmount = setGuaranteeAmount();
    let leftAmount = 0;
    if (currentGuaranteeAmount) {
      leftAmount = currentGuaranteeAmount - 1;
      setGuaranteeAmount(leftAmount);
    }
    setTimeout(() => {
      eventManager.emit(
        EventTypes.THOR_ATTACK,
        currentGuaranteeAmount ? ThorMovements.guaranteePlusWPowerAttack : ThorMovements.wPowerAttack,
        currentGuaranteeAmount ? ThorMovements.guaranteePlusWPowerLoop : ThorMovements.wPowerIdle,
      );
    }, 0);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;
      if (!leftAmount && currentGuaranteeAmount) {
        eventManager.emit(EventTypes.THOR_GUARANTEE_OUT);
        eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS_ICON, 'invincible_icon', false);
      }
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards((currentBlockToBreak as GeneratedReelSet).rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private thorGuaranteeHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    const guaranteeAmount = setGuaranteeAmount();
    const leftAmount = guaranteeAmount - 1;
    setTimeout(() => {
      eventManager.emit(EventTypes.THOR_ATTACK, ThorMovements.guaranteeAttack, ThorMovements.guaranteeIdle);
    }, 0);
    setGuaranteeAmount(leftAmount);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;

      if (!leftAmount) {
        eventManager.emit(EventTypes.THOR_GUARANTEE_OUT);
        eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS_ICON, 'invincible_icon', false);
      }
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards(currentBlockToBreak.rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private checkThorRewards(reward: ThorRewards | null, icons: Icon[]): void {
    const newReward = reward?.includes('MULTIPLIER') || reward?.includes('GUARANTEE') ? reward.split('_')[0] : reward;
    switch (newReward) {
      case 'GUARANTEE':
        this.updateGuaranteeWin(reward as string, icons);
        break;
      case 'D_W_POWER':
        this.updatePowers(reward as ThorPowers, icons);
        break;
      default:
        this.startCascadeWinAnimation(icons, false);
        break;
    }
  }

  private updateGuaranteeWin(guarantee: string, icons: Icon[]): void {
    const guaranteeAmount = +guarantee.split('_')[1]! as number;
    const currentGuaranteeAmount = setGuaranteeAmount();
    setGuaranteeAmount(currentGuaranteeAmount + guaranteeAmount);
    setIsSuperPowerEnabled(true);
    eventManager.emit(EventTypes.THOR_GUARANTEE_IN);
    eventManager.emit(EventTypes.THOR_GUARANTEE_IDLE);
    this.startCascadeWinAnimation(icons, true);
  }

  private updatePowers(_powers: ThorPowers, icons: Icon[]): void {
    setWPower(true);
    setIsSuperPowerEnabled(true);
    const currentGuaranteeAmount = setGuaranteeAmount();
    if (currentGuaranteeAmount) {
      setGuaranteeAmount(currentGuaranteeAmount + 1);
    }
    eventManager.emit(EventTypes.THOR_W_POWER_IN);
    this.startCascadeWinAnimation(icons, true);
  }

  private startCascadeWinAnimation = (icons: Icon[], superPower: boolean) => {
    eventManager.emit(EventTypes.START_CASCADE_WIN_ANIMATION, icons, superPower);
    eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS);
  };
}
