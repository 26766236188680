import { useQuery } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { queryParams } from '../../utils';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(slotConfigGql);
  const { isMobile } = data!;

  return (
    <div className={styles['buttonBehaviors']}>
      <h1 className={styles['title']}>{t('infoButtonBehaviorsTitle')}</h1>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnSpin)} alt="infoBtnSpin" />
        {t('infoButtonBehaviorsSpin')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnStop)} alt="infoBtnStop" />
        {t('infoButtonBehaviorsStop')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnAutoplay)} alt="infoBtnAutoPlay" />
        {t('infoButtonBehaviorsAutoPlay')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnAutoplayStop)} alt="infoBtnAutoplayStop" />
        {t('infoButtonBehaviorsAutoPlayStop')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnBetSettings)} alt="infoBtnBetSettings" />
        {t('infoButtonBehaviorsBetSettings')}
      </div>
      {!isMobile && (
        <div className={styles['buttonBehaviors__item']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnTurbo)} alt="infoBtnTurbo" />
          {t('infoButtonBehaviorsTurbo')}
        </div>
      )}
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnMenu)} alt="infoBtnMenu" />
        {t('infoButtonBehaviorsMenu')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnSound)} alt="infoBtnSound" />
        {t('infoButtonBehaviorsSound')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img
          draggable="false"
          className={styles['info_icon']}
          src={Resources.getSource(ResourceTypes.infoBtnInfo)}
          alt="infoButtonInfo"
        />
        {t('infoButtonBehaviorsInfo')}
      </div>
      <h1 className={styles['title']}>{t('infoButtonBehaviorsBetSettingsTitle')}</h1>
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsBetSettingsText'),
        }}
      />
      <h1 className={styles['title']}>{t('infoButtonBehaviorsAutoPlaySettingsTitle')}</h1>
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsAutoPlaySettingsText'),
        }}
      />
      <div className={styles['buttonBehaviors__item']}>
        <img
          draggable="false"
          className={styles['bigger']}
          src={Resources.getSource(ResourceTypes.buttonOk)}
          alt="infoBtnOk"
        />
        {t('infoButtonBehaviorsAutoPlayStart')}
      </div>

      <h1 className={styles['title']}>{t('infoButtonBehaviorsMenuTitle')}</h1>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonInfo)} alt="infoBtnInfo" />
        {t('infoButtonBehaviorsMenuOpen')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonSetting)} alt="infoBtnSetting" />
        {t('infoButtonBehaviorsMenuSettings')}
      </div>
      {lobbyBtnAvailable(queryParams) && (
        <div className={styles['buttonBehaviors__item']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.buttonLobby)} alt="infoBtnLobby" />
          {t('infoButtonBehaviorsMenuLobby')}
        </div>
      )}
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonHistory)} alt="infoBtnHistory" />
        {t('infoButtonBehaviorsMenuHistory')}
      </div>
      <div
        className={styles['p']}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsDescription'),
        }}
      />
    </div>
  );
};

export default ButtonBehaviors;
